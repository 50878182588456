@import '~@csstools/normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html,
body,
#root {
  background-color: #f8f8fb; /* Ice 400 */
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

html,
html body,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: 'Open Sans', sans-serif;
}

/* Make selected text more Therapie looking */
::-moz-selection {
  background: #7c74c1;
  color: white;
}

::selection {
  background: #7c74c1;
  color: white;
}
